<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>

        <div class="good-list-global" ref="good">
            <goodListGlobal v-for="(item, index) in list" :key="index" @click.native="$router.push({ path: '/ActivityDetail', query: { id: item.id } })" :item="item"></goodListGlobal>
        </div>

        <el-pagination class="pagination" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
// import * as training from '@/api';
import { getConsultingList } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '活动',
                    to: { path: '/' },
                },
                {
                    title: '精彩活动',
                    to: { path: '/my', query: { tabs: '0' } },
                },
            ],
            search: '',
            venue: [],
            venueValue: '',
            holdValue: '',
            hold: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '演出',
                    value: 0,
                },
                {
                    label: '讲座',
                    value: 1,
                },
                {
                    label: '展览',
                    value: 2,
                },
                {
                    label: '会议',
                    value: 3,
                },
            ],
            value: '',
            intelligentValue: '',
            intelligent: [
                {
                    label: '默认',
                    value: '',
                },
                {
                    label: '即将开始',
                    value: 0,
                },
                {
                    label: '最新发布',
                    value: 1,
                },
                {
                    label: '人气',
                    value: 2,
                },
            ],
            pageNum: 1,
            pageSize: 12,
            total: 0,
            list: [],
        };
    },
    props: {},
    // 方法集合
    methods: {
        getActivityList() {
            let data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: this.holdValue,
                intelligentSort: this.intelligentValue,
                libraryName: this.venueValue,
                name: this.search,
            };

            getConsultingList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            // this.getActivityList();
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {
        search() {
            this.pageNum = 1;
            this.getActivityList();
        },
    },
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getActivityList();
        this.getLibraryLists();
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.box {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            .col {
                display: flex;
                align-items: center;
                margin-right: 24px;
                span {
                    font-size: 13px;
                    color: #333333;
                }
                .el-select {
                    width: 160px;
                }
                ::v-deep .el-input__inner {
                    border: 0;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 24px;
            }
            ::v-deep .el-input__inner,
            ::v-deep .el-input {
                border-radius: 20px;
                width: 240px;
                height: 30px;
            }
            ::v-deep .el-input__prefix {
                margin-top: -3px;
                //i {
                //    height: 30px;
                //}
            }
        }
    }
    .good-list-global {
        //一行三列
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px 20px;
        padding-top: 20px;
    }
    .pagination {
        margin: 20px auto;
    }
}
</style>
